import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, useLocation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import Register from './common/register';

import PriceTrendSection from '../components/project/transactions/priceTrendSection';

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Button from '@mui/material/Button';
import { capitalizeFirstLetter, formatNumber } from '../utils';
import { Bathroom, Bed, Frame, XmarkCircle, SkipNext, SkipPrev } from 'iconoir-react';
import API from '../controllers/secureApi'

import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";

import "yet-another-react-lightbox/plugins/thumbnails.css";
import MakeOffer from './specials/MakeOffer.js';
import PleaseVerify from './common/pleaseVerify.js';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Special = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const { id } = useParams();

  const [property, setProperty] = useState({});
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  const [openLightBox, setOpenLightBox] = useState(false);

  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const handleCloseMakeOffer = () => setOpenMakeOffer(false);
  const handleCloseContact = () => setOpenContact(false);

  const imageTemplate = {
    1: [
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 4,
        cols: 4,
      },
    ],
    2: [
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 2,
        cols: 2,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 2,
        cols: 2,
      },
    ],
    3: [
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 2,
        cols: 2,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 1,
        cols: 2,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 1,
        cols: 2,
      },
    ],
    4: [
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 2,
        cols: 2,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 1,
        cols: 2,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 1,
        cols: 1,
      },
      {
        img: "https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp",
        rows: 1,
        cols: 1,
      }
    ]
  }


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await API.get(`offers/${id}`);
      const images = result.data.images
      const countImages = images.length > 4 ? 4 : images.length
      imageTemplate[countImages].forEach((image, index) => {
        if (images[index]) {
          image.img = images[index].url || 'https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp'
          image.src = images[index].url || 'https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp'
        }
      })
      console.log(result)
      setImages(imageTemplate[countImages]);
      setProperty(result.data);
      setLoading(false);
    };
    if (!dbUser) {
      return;
    }
    fetchData();
  }, [dbUser, id]);

  return (
    <>
      <Lightbox
        open={openLightBox}
        close={() => setOpenLightBox(false)}
        slides={images}
        carousel={2}
        plugins={[Thumbnails]}
        styles={{
          container: {
            background: "rgba(0, 0, 0, 0.9)",
          },
          root: {
            "yarl__thumbnails_container": { background: "rgba(0, 0, 0, 0.9)" },
          },
        }}
        render={{
          iconPrev: () => <SkipPrev />,
          iconNext: () => <SkipNext />,
          iconClose: () => <XmarkCircle />,
        }}
        thumbnails={{
          position: 'bottom',
          width: 120,
          height: 80,
          border: 1,
          borderRadius: 3,
          padding: 4,
          gap: 16,
          showToggle: false,
        }}
      />
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          {dbUser === undefined ? (null) : !dbUser ? <Register forpro={false} preview={'empty'} height={'500px'} /> : (
            <>
              <Grid2 container spacing={2}>
                <Grid2 container xs={12} md={12} pb={3} sx={{ alignContent: 'center', alignItems: 'center' }}>
                  <Grid2 item xs={1} md={0.5} sx={{ align: 'left' }} >
                    <div style={{ textAlign: 'left' }}>
                      <IconButton aria-label="close" size="meddium" component="label" onClick={() => { navigate('/specials') }} pb={0}>
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </Grid2>
                  <Grid2 item xs={11} md={11.5} pb={1}>
                    <Typography variant="h1" sx={{
                      fontSize: '2rem',
                      pb: 1,
                    }}>
                      {loading ? <Skeleton /> : `${property?.type} in ${property?.project?.name}`}
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ paddingRight: { xs: 0, sm: '10px' } }}>
                      <Link color="inherit" href="/specials">
                        <Typography variant="subtitle1">Specials</Typography>
                      </Link>
                      <Typography variant="subtitle1">Property details</Typography>
                    </Breadcrumbs>
                  </Grid2>
                </Grid2>
                <Divider />


                <Grid2 container xs={12} md={8} p={2}>
                  {loading ? <Box sx={{ width: '100%' }}><Skeleton inline width={'100%'} height={'100%'} /></Box> : (
                    <ImageList
                      sx={{ width: '100vw' }}
                      variant="quilted"
                      cols={4}
                    // rowHeight={121}
                    >
                      {images?.map((item, index) => (
                        <ImageListItem
                          key={item.img}
                          cols={item.cols || 1}
                          rows={item.rows || 1}
                        >
                          <img
                            {...srcset(item.img, 121, item.rows, item.cols)}
                            alt={item.title}
                            loading="lazy"
                            onClick={() => { setOpenLightBox(true) }}
                          />
                          {index === images.length - 1 && images.length > 4 && (
                            <Box sx={{
                              position: "absolute",
                              top: "0",
                              width: "100%",
                              height: "100%",
                              padding: '20%',
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}><Box sx={{
                              width: "100%",
                              height: "100%",
                              background: "#ffffff8f",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "10%"
                            }}><Typography variant='h1'>+{images.length - 4}</Typography></Box></Box>
                          )}
                        </ImageListItem>
                      ))}
                    </ImageList>
                  )}
                </Grid2>
                <Grid2 container xs={12} md={4}>
                  <Grid2 item xs={12} md={12} pb={1}>
                    <Box sx={{ border: '1px solid #dddddd', padding: 2, borderRadius: 2 }}>
                      <Typography variant="h3" sx={{
                        pb: 3,
                        fontWeight: 800,
                      }}>
                        Agent details
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pb: 3 }}>
                        <Avatar sx={{ width: 45, height: 45 }} aria-label="recipe" variant="rounded" src={property?.user?.avatar}>
                          R
                        </Avatar>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} pl={1}>
                          <Typography variant="h4">{loading ? <Skeleton /> : property?.user?.name.split(' ')[0]}</Typography>
                          <Typography variant="subtitle1" pb={0}>{loading ? <Skeleton width={'100px'} /> :
                            `PRO ${property?.user?.type === 'BROKER' ? '● BROKER' : ''}`}
                          </Typography>
                        </Box>

                      </Box>
                      <Button variant="contained" disabled={loading} onClick={() => setOpenContact(true)}>Contact agent</Button>
                    </Box>
                    <Box sx={{ height: '270px' }}>
                      {loading ? <Skeleton width={'100%'} height={'270px'} /> : (
                        <img
                          style={{ borderRadius: '10px', marginTop: '16px' }}
                          src={`https://api.mapbox.com/styles/v1/mapbox/light-v11/static/pin-l+f22626(${property?.project?.coordinates?.lng},${property?.project?.coordinates?.lat})/${property?.project?.coordinates?.lng},${property?.project?.coordinates?.lat},12,0/300x200@2x?access_token=pk.eyJ1IjoiZXVnYW5veCIsImEiOiJjbGcza3Q2bDYwZ2FjM2VuOW5zNGl1MzBhIn0.UdtHbQyNWdcaZJmObkPuPA`} alt="map" />
                      )}
                    </Box>
                  </Grid2>
                  <Divider />
                </Grid2>
              </Grid2>
              <Grid2 container xs={12} md={12} pt={2} >
                <Grid2 container xs={12} md={8} >
                  <Grid2 container xs={6} md={6} p={0} >
                    <Grid2 container xs={12} md={12} p={0}>
                      <Typography variant="h1" sx={{
                        pb: 3,
                        fontWeight: 800,
                        color: '#fc5c65',
                        paddingBottom: '10px',
                      }}>
                        {loading ? <Skeleton width={'230px'} baseColor={'#fc5c65'} /> : property?.priceBrokersOnly ? `**** AED` : `${formatNumber(property?.price)} AED`}
                      </Typography>
                    </Grid2>
                    <Grid2 container xs={12} md={12} p={0}>
                      <Typography variant="h4" sx={{
                        pb: 3,
                        fontWeight: 800,
                        paddingBottom: '14px',
                      }}>
                        {loading ? <Skeleton width={'230px'} /> : `${property?.project?.name}, ${property?.project?.district?.name}`}
                      </Typography>
                    </Grid2>
                    <Grid2 container xs={12} md={12} p={0}>
                      <Bed strokeWidth={2} color='black' style={{ marginRight: 3 }} />
                      <Typography variant="body2" sx={{ marginTop: '3px', paddingRight: 2 }}>{loading ? <Skeleton width={'30px'} /> : `${formatNumber(property?.bedrooms)} Beds`}</Typography>

                      <Bathroom strokeWidth={2} color='black' style={{ marginRight: 3 }} />
                      <Typography variant="body2" sx={{ marginTop: '3px', paddingRight: 2 }}>{loading ? <Skeleton width={'30px'} /> : `${formatNumber(property?.bathrooms)} Baths`}</Typography>

                      <Frame strokeWidth={2} color='black' style={{ marginRight: 3 }} />
                      <Typography variant="body2" sx={{ marginTop: '3px', paddingRight: 2 }}>{loading ? <Skeleton width={'30px'} /> : `${formatNumber(property?.size)} Ft²`}</Typography>
                      <Divider />
                    </Grid2>
                  </Grid2>
                  <Grid2 container xs={6} md={6} p={0} justifyContent={'flex-end'} alignItems={'center'}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Button variant="outlined" disabled={loading} onClick={() => setOpenContact(true)}>Contact agent</Button>
                      {property?.priceOffer && (
                        <Button variant="contained" sx={{ marginTop: 2 }} disabled={loading} onClick={() => setOpenMakeOffer(true)}>Offer your price</Button>
                      )}
                    </Box>
                  </Grid2>
                  <Box sx={{ borderBottom: '1px solid #dddddd', width: '100%', padding: 2 }}>
                  </Box>


                  <Grid2 container xs={12} md={12} pt={4} direction={'row'}>
                    <Typography variant="h1" sx={{
                      pb: 3,
                      fontWeight: 800,
                    }}>
                      {loading ? <Skeleton width={'300px'} /> : property?.title}
                    </Typography>
                    <Typography variant="body1" sx={{
                      pb: 3,
                      fontWeight: 400,
                      fontSize: '1rem',
                      lineHeight: 2,
                      width: '100%',
                    }}>
                      {loading ? <>
                        <Skeleton padding={2} width={'100%'} height={'10px'} count={6} />
                      </> : property?.description}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 container xs={12} md={4} pl={2}>
                  <Box sx={{ border: '1px solid #dddddd', padding: 2, borderRadius: 2, width: '100%', height: 'max-content' }}>
                    <Typography variant="h3" sx={{
                      pb: 3,
                      fontWeight: 800,
                    }}>
                      Commision and floor
                    </Typography>
                    {dbUser?.type !== 'BROKER' ? (
                      <PleaseVerify />
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pb: 3 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} pl={1}>
                          <Typography variant="h4">{loading ? <Skeleton /> : `${formatNumber(property?.commision)}%`}</Typography>
                          <Typography variant="subtitle1" pb={0}>{loading ? <Skeleton width={'100px'} /> : 'Commision shared with buyer broker'}</Typography>

                          <Typography variant="h4" pt={3}>{loading ? <Skeleton /> : capitalizeFirstLetter(property?.floor)}</Typography>
                          <Typography variant="subtitle1" pb={0}>{loading ? <Skeleton width={'100px'} /> : 'Floor'}</Typography>
                        </Box>

                      </Box>
                    )}
                  </Box>
                </Grid2>
              </Grid2>


              <Grid2 container xs={12} md={12} pt={2} pb={10}>

                <PriceTrendSection
                  user={user}
                  dbUser={dbUser}
                  hideFilter={true}
                  selectedId={property?.project?.id}
                />
              </Grid2>
            </>
          )}
        </Container>
      </Box>
      {property?.priceOffer && (
        <MakeOffer open={openMakeOffer} handleClose={handleCloseMakeOffer} priceOffer={true} item={property} priceHidden={property?.priceBrokersOnly} />
      )}
      <MakeOffer open={openContact} handleClose={handleCloseContact} priceOffer={false} item={property} />
    </>
  );
}

export default Special;