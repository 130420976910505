import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 13,
    lineHeight: '1.5',
    padding: '10px 15px',
  },
});

const CustomizedTooltips = (props) => {
  const title = props.title
  return (
    <CustomWidthTooltip title={title}><InfoIcon sx={{ fontSize: 14, marginLeft: 1 }}/></CustomWidthTooltip>
  );
}

export default CustomizedTooltips;