import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";

import DummyItem from './specials/DummyItem.js';
import FullItem from './specials/FullItem.js';
import MySpecials from './specials/MySpecials.js';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import API from '../controllers/secureApi';
import PublicAPI from '../controllers/api.js';

import Register from './common/register';
import TabsApple from './common/TabsApple';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Specials = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);
  const { tab } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [offers, setOffers] = useState([]);

  // Tabs
  const [value, setValue] = useState(tab === 'mydeals' ? 1 : 0);


  const tabsMapping = [ 
    "/specials",
    "/specials/mydeals",
  ];

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
    navigate(tabsMapping[newValue], { replace: true });
  };


  useEffect(() => {
    const fetchOffers = async () => {
      if (value === 0) {
        setLoading(true);
        let data;
        if(dbUser) {
          data = await API.get('/offers');
        } else {
          data = await PublicAPI.get('/offers/public');
        }
        setOffers(data.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else if (value === 1) {
        setLoading(false)
      }
    }
    fetchOffers();
  }, [dbUser, value]);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} pb={3}>
            <Grid2 container xs={12} md={12} >
              <Grid2 xs={12} md={10} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {/* {t('favourites.title')} */} Special Offers
                </Typography>
                <Typography variant="subtitle1">Finding the perfect deal for your clients</Typography>
              </Grid2>
              <Grid2 xs={12} md={2} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button color="primary" variant='contained' disabled={loading} onClick={() => navigate('/specials/add')}>
                  Add your offer
                </Button>
              </Grid2>
              <Divider sx={{width: '100%'}}/>
              </Grid2>
              <Divider />
            </Grid2>
            {dbUser === undefined ? (null) 
              // : !dbUser ? <Register forpro={false} preview={'empty'} height={'500px'} /> 
              : (
              <>

                <Grid2 container xs={12} md={12} p={2} pb={3} pt={1}>
                  <TabsApple handleChange={handleChange} tab={value} />
                </Grid2>

                <CustomTabPanel value={value} index={0} style={{ width: '100%' }}>
                  <Grid2 container xs={12} md={12} p={0}>
                    {loading ? (
                      <>
                        <DummyItem navigate={navigate} isLoader />
                        <DummyItem navigate={navigate} isLoader />
                        <DummyItem navigate={navigate} isLoader />
                      </>
                    ) : offers?.map((item) => (
                      <>
                        {!item.hidden ? (
                          <FullItem item={item} navigate={navigate} />
                        ) : (
                          <DummyItem navigate={navigate} item={item} />
                        )}
                      </>
                    ))}


                  </Grid2>
                </CustomTabPanel>


                <CustomTabPanel value={value} index={1} style={{ width: '100%' }}>
                  <Grid2 container xs={12} md={12} p={0}>
                    <MySpecials />
                  </Grid2>
                </CustomTabPanel>
              </>)}


          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Specials;