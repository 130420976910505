import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { MapPinIcon } from '@heroicons/react/24/outline';

import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

import Register from './common/register.js';

import { calculatePercentChange } from '../utils';

import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

import apiInstance from '../controllers/secureApi';
import QuiteHere from '../components/common/quiteHere';

import Chart from 'react-apexcharts'

const Favourites = (props) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [favourites, setFavourites] = useState([]);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const removeEntryFromFavourites = (entryId) => {
    if (!entryId) return;
    const newFavourites = favourites.filter((item) => item.id !== entryId);
    setFavourites(newFavourites);
  }


  const Fav = (props) => {
    const { data, removeEntryFromFavourites } = props;
    const [series, setSeries] = useState([{
      name: 'series-1',
      data: []
    }]);

    const [localLoading, setLocalLoading] = useState(true);
    const [graphLoading, setGraphLoading] = useState(true);
    const [favData, setFavData] = useState({});
    const [options, setOptions] = useState({
      chart: {
        type: 'line',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      colors: ['#ff5252'],
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false,
        },
        y: {
          show: false,
          title: {
            formatter: function (seriesName) {
              return ''
            }
          },
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return value
          }
        },
        marker: {
          show: false
        }
      }
    });

    const handleUnLike = async (entryId) => {
      if (!user || !entryId) return;
      // setIsLikedLoading(true);

      const dataSend = {
        liked: false
      }
      await apiInstance.post(`/like/${entryId}`, dataSend);
      removeEntryFromFavourites(entryId);
    }

    useEffect(() => {
      if (!data) return;

      const fetchGraphData = async () => {
        try {
          setGraphLoading(true);
          if (data.district) {
            const { data: graphData } = await apiInstance.get(`/districts/${data.district.id}/avgprice?yearstart=2023&yearend=2023&propFilter=fv&regTypeFilter=or&roomsTypeFilter=all`);
            const formated = graphData.map((item) => item.avgsqmprice);
            console.log(formated)
            setSeries([{
              name: 'series-1',
              data: formated
            }]);
          } else if (data.project) {
            const { data: graphData } = await apiInstance.get(`project/${data.project.id}/transactions-by-month?yearstart=2023&yearend=2023&roomsTypeFilter=all`);
            const formated = graphData.map((item) => item.avggensqmprice);
            console.log(formated)
            setSeries([{
              name: 'series-1',
              data: formated
            }]);
          }
          setGraphLoading(false);
        } catch (error) {
          console.log(error);
        }
      }



      const formatData = (data) => {
        if (!data) return;
        setLocalLoading(true);
        const formattedData = data.project ? {
          entryId: data.id,
          id: data.project.id,
          name: data.project.name,
          breadcrumbs: [
            'Dubai',
            data.project.district.name,
          ],
          type: 'project',
          url: `/district/${data.project.district.id}/project/${data.project.id}`,
        } : {
          entryId: data.id,
          id: data.district.id,
          name: data.district.name,
          breadcrumbs: [
            'Dubai',
            data.district.name,
          ],
          type: 'district',
          url: `/district/${data.district.id}`,
        };
        setFavData(formattedData)
        setLocalLoading(false);
      }
      formatData(data);
      fetchGraphData();
    }, [data]);

    return (
      <Grid2 item xs={12} sm={4} container sx={{ padding: 2 }}>
        <Grid2 item xs={12} sm={12} container sx={{ border: '1px solid #0000001f', borderRadius: '5px', padding: 2 }}>
          <Grid2 item xs={8} sm={10}>
            {localLoading ? (
              <Skeleton variant="rectangular" width={'100%'} height={50} />
            ) : (
              <>
                <Typography variant="h1" sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                  {favData.type === 'project' ? <BuildingOfficeIcon style={{ height: '16px' }} /> : <MapPinIcon style={{ height: '16px' }} />} {favData.name}
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  {favData.breadcrumbs?.map((item, index) => (
                    <Typography key={index} variant="subtitle1">{item}</Typography>
                  ))}
                </Breadcrumbs>
              </>
            )}
          </Grid2>
          <Grid2 item xs={2} sm={2} style={{ align: 'right' }} >
            {localLoading ? (
              <Skeleton variant="rectangular" style={{ marginLeft: 5 }} width={'100%'} height={50} />
            ) : (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton aria-label="close" size="meddium" component="label" onClick={async () => {
                    await handleUnLike(favData.entryId)
                  }} pb={0}>
                    <BookmarkIconSolid stroke-width="1.5" style={{ color: '#000', width: 25, margin: '2px' }} />
                  </IconButton>
                </div>
              </>
            )}
          </Grid2>
          <Grid2 item xs={12} sm={8} >
            {graphLoading ?
              <Skeleton variant="rectangular" width={'100%'} height={100} />
              :
              <Chart options={options} series={series} type="line" height={100} />
            }
          </Grid2>

          <Grid2 item xs={12} sm={4} container sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            textAlign: 'center',
          }}>
            {graphLoading ? (
              <Grid2 item xs={12} sm={12}>
                <Skeleton variant="rectangular" style={{ marginLeft: 5 }} width={'100%'} height={100} />
              </Grid2>
            ) : (
              <>
                {series[0].data.length > 0 && (
                  <Typography variant="h1">
                    {calculatePercentChange(series[0].data[series[0].data.length - 1], series[0].data[0])} %
                    {calculatePercentChange(series[0].data[series[0].data.length - 1], series[0].data[0]) > 0 ? (
                      <NorthEastIcon color={'success'} fontSize="14" />
                    ) : (
                      <SouthEastIcon color={'error'} fontSize="14" />
                    )}
                  </Typography>
                )}
              </>
            )}
          </Grid2>
          <Link href={favData.url} variant="body2" sx={{ textAlign: 'right', width: '100%', cursor: 'pointer', textDecoration: 'none', fontWeight: 600 }}>
            <ArrowRightCircleIcon height={30} style={{ marginLeft: 'auto' }} />
          </Link>

        </Grid2>
      </Grid2>
    )
  }



  useEffect(() => {

    const fetchFavorites = async () => {
      try {
        if (!dbUser) return;
        setLoading(true);
        const { data } = await apiInstance.get(`/like/all`);
        setFavourites(data);
        console.log(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchFavorites();
  }, [dbUser]);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: {xs: 0, sm: '95px'}}}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {t('favourites.title')}
                </Typography>
                <Typography variant="subtitle1">{t('favourites.subTitle')}</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            <Grid2 container xs={12} md={12} p={0} justifyContent={'center'}>
              {!user ? (
                <Register preview={'empty'} />
              ) : (
                <>
                  {loading && (
                    <>
                      <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                      </Grid2>
                      <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                      </Grid2>
                      <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                      </Grid2>
                    </>
                  )}
                  {!loading && favourites.length === 0 ? (
                    <Grid2 container xs={12} md={12} p={0} direction={'column'} pt={3}>
                      <Grid2 item xs={12}>
                        <QuiteHere message={t('favourites.noData')} />
                      </Grid2>
                      <Grid2 xs={12}>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: '20px',
                        }}>
                          <Button
                            variant="contained"
                            onClick={() => navigate(`/`)}
                            sx={{ my: 1, display: { xs: 'none', sm: 'block' } }}
                          >
                            {t('general.expoloremap')}
                          </Button>
                        </Box>
                      </Grid2>
                    </Grid2>
                  ) : (
                    <>
                      {favourites.map((item, index) => (
                        <Fav data={item} removeEntryFromFavourites={removeEntryFromFavourites} />
                      ))
                      }
                    </>
                  )}
                </>
              )}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Favourites;