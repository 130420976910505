import React from 'react';
import * as Sentry from "@sentry/react";
import { AuthProvider } from "./contexts/AuthProvider";
import { SubscriptionProvider } from "./contexts/SubscriptionProvider";
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import './theme/index.css';
import Map from './Map';
import Login from './Login';
import Register from './Register'
import ResetPassword from './ResetPassword';
import Checkout from './Checkout';
import ErrorPage from "./Error";
import DistrictStandalone from "./components/DistrictStandalone";
import Dubai from "./components/Dubai";
import Favourites from "./components/Favourites";
import About from "./components/About";
import Wallet from './components/Wallet';
import Payment from './Payment';
import PaymentSuccess from './PaymentSuccess';
import Feedback from './components/Feedback';
import AddAd from './components/SpecialAdd';
import Specials from './components/Specials';
import Special from './components/Special';
import Verify from './components/Verify';
// import Cookies from "./Cookies";  
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { baseThemeOptions } from './theme/base-theme-options';
import { lightThemeOptions } from './theme/light-theme-options';
import './i18n';

import Plausible from 'plausible-tracker'

if (process.env.NODE_ENV === 'production') {
  console.log('Production mode. All console logs are disabled.')
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

Sentry.init({
  dsn: "https://c06f92f0d82f46d5a4052194b3b5ee17@o440755.ingest.sentry.io/4505155918364672",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  })],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { enableAutoPageviews } = Plausible({
  domain: 'mappi.ae',
  // trackLocalhost: true,
})
enableAutoPageviews()

const theme = createTheme({
  baseThemeOptions,
  lightThemeOptions,
  components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            fontWeight: 500,
            paddingLeft: 16,
            paddingRight: 16,
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                fontSize: '16px',
              }),
          }),
        }
      },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     head: {
    //       fontWeight: '600',
    //       color: '#84817a',
    //     },
    //     root: {  //This can be referred from Material UI API documentation. 
    //       padding: '6px 0px',
    //     },
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: '600',
          fontSize: '14px',
        },
        colorError: {
          color: '#b54708',
          backgroundColor: 'rgba(247, 144, 9, 0.12)',
        },
        colorSuccess: {
          color: '#0b815a',
          backgroundColor: 'rgba(16, 185, 129, 0.12)',
        },
        success: {
          main: 'rgba(16, 185, 129, 0.12)',
        },
        error: {
          main: 'rgba(247, 144, 9, 0.12)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          colorPrimary: '#000',
          colorDefault: '#000',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: '0px',
          maxWidth: '100%',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1a1a1a',
    },
    success: {
      main: '#20bf6b',
    },
    error: {
      main: '#fc5c65',
    },
    warning: {
      main: '#fed330',
    },
    info: {
      main: '#ffda79',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle2: {
      fontSize: 12,
      color: '#b9b9b9',
    },
    subtitle1: {
      fontSize: 13,
      color: '#84817a',
    },
    h1: {
      fontSize: 22,
      fontWeight: 900,
      color: '#1a1a1a',
    },
    rating: {
      fontSize: 22,
      fontWeight: 900,
      color: '#545454',
    },
    body1: {
      fontSize: 14,
    },
    content: {
      fontSize: 16,
      lineHeight: 2,
      paddingBottom: '24px',
      display: 'block',
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      color: '#545454',
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
      color: '#545454',
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      color: '#545454',
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      color: '#1a1a1a',
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      color: '#545454',
    },
    subText: {
      fontSize: 14,
      fontWeight: 400,
      color: '#1a1a1a',
    },
    smallCurrency: {
      fontSize: 11,
      fontWeight: 600,
      color: '#b9b9b9',
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Map />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/district/:districtIdParam",
    element: <Map />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/district/:districtIdParam/project/:projectIdParam",
    element: <Map />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dubai",
    element: <Dubai />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/favourites",
    element: <Favourites />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/wallet",
    element: <Wallet />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/payment",
    element: <Payment />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/payment/status",
    element: <PaymentSuccess />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/specials",
    element: <Specials />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/specials/:tab",
    element: <Specials />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/special/:id",
    element: <Special />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/specials/add",
    element: <AddAd />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/broker/verify",
    element: <Verify />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/cookies",
  //   element: <Cookies />,
  //   errorElement: <ErrorPage />,
  // },
]);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <SubscriptionProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </SubscriptionProvider>
    </AuthProvider>
  // </React.StrictMode>
);
