import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import API from '../controllers/api.js'
import secureAPI from '../controllers/secureApi.js'
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import FormHelperText from '@mui/material/FormHelperText';

import FilesDropzone from './common/FilesDropzone.js';

import { useForm } from 'react-hook-form';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { NumericFormat } from "react-number-format";

import Autocomplete from '@mui/material/Autocomplete';
import Register from './common/register.js';
import PleaseVerify from './common/pleaseVerify.js';

const AddAd = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = useState('');

  const [projectId, setProjectId] = useState(null);
  const [projectIdError, setProjectIdError] = useState(false);

  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateError, setGenerateError] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, reset } = useForm({
    defaultValues: {
      description: ''
    },
  });
  const onSubmit = async data => {
    if (!projectId) {
      setProjectIdError(true)
      return
    }
    const finalData = {
      ...data,
      projectId,
    }
    console.log('finalData')
    console.log(finalData)
    try {
      const data = await secureAPI.post('/offer', finalData)
      if (data.status === 200) {
        reset()
        navigate('/specials')
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  // console.log(errors);

  const generateDesc = async () => {
    setGenerateLoading(true)
    const dataToSend = getValues()
    if (!dataToSend.projectName || !dataToSend.bedrooms || !dataToSend.bathrooms || !dataToSend.floor || !dataToSend.size || !dataToSend.type || !dataToSend.price) {
      setGenerateError(true)
      setGenerateLoading(false)
      return
    }
    const { data: response } = await API.post('/experiments/ai/dubai-content', dataToSend)
    await setValue('description', response.result)
    setGenerateError(false)
    setGenerateLoading(false)
  }

  useEffect(() => {
    if (dbUser) {
      console.log('dbUser')
      console.log(dbUser)
      setValue('email', dbUser.email)
    }
  }, [dbUser]);


  useEffect(() => {
    const fetchProjects = async () => {
      const { data } = await API.get(`/search/projects?q=${inputValue}`)
      const hits = data.hits || []
      setOptions(hits);
    }
    fetchProjects()
  }, [inputValue, searchValue]);

  console.log(errors)

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  Add Special Offer
                </Typography>
                <Typography variant="subtitle1">It will take just a minute or two</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            <Grid2 xs={12} md={12} p={0}>
              {dbUser === undefined ? (null) : !user ? <Register height={'300px'} preview={'empty'} /> : !dbUser ? (null) : dbUser?.type !== 'BROKER' ? (
                <PleaseVerify height={'300px'} />
              ) : (
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1 },
                    '& button': { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("priceOffer", {})} />} label="Allow counter offers (propose your price)" />
                    <Typography variant="subtitle1">This will allow users to submit the counter offer to you, for example, below the ask price. You will be able to decide if you want to proceed with it.</Typography>
                    <FormControlLabel control={<Checkbox color={'error'} margin="normal" {...register("showProOnly", {})} />} label="Show only to Verified Agents" />
                    <Typography variant="subtitle1">Only verified Real Estate Agents (registered with DLD) will be able to see this offer. Please use this option in case you want to limit the exposure of the offer, however keep in mind it can drastically reduce the amount of offers.</Typography>

                  </FormGroup>

                  <Grid2 xs={12} md={12} pb={1} pt={2}>
                    <Typography variant="h2" sx={{
                      pb: 1,
                      fontWeight: 800,
                      color: '#000'
                    }}>
                      About the property
                    </Typography>
                  </Grid2>
                  <Divider />

                  <FormGroup>
                    <Autocomplete
                      id="asynchronous-demo"
                      sx={{
                        width: 300, '& MuiAutocomplete-popupIndicator': {
                          margin: '0px !important'
                        }
                      }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.projectId === value.projectId}
                      getOptionLabel={(option) => option.projectName}
                      options={options}
                      filterOptions={(x) => x}
                      onChange={(event, newValue) => {
                        console.log('newValue')
                        console.log(newValue)
                        setOptions(newValue ? [newValue, ...options] : options);
                        setSearchValue(newValue);
                        setProjectId(newValue?.projectId || null)
                        if (!newValue?.projectId) {
                          setProjectIdError(true)
                        } else {
                          setProjectIdError(false)
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="normal"
                          label="Project name"
                          sx={{
                            '& button': {
                              marginTop: '0px !important'
                            }
                          }}
                          {...register("projectName", { required: { value: true, message: 'Project is required' } })}
                          error={Boolean(errors.projectName)} helperText={errors.projectName ? errors.projectName.message : ''}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}

                      renderOption={(props, option) => {

                        return (
                          <div key={option.projectId}>
                            <ListItemButton {...props}>
                              <ListItemText
                                sx={{ color: 'black' }}
                                primary={option.projectName}
                                secondary={option.districtName}
                              />
                            </ListItemButton>
                            <Divider component="li" />
                          </div>
                        );
                      }}
                    />
                    {/* <TextField sx={{display: 'none'}} {...register("projectId", { required: true })}  margin="normal" fullWidth /> */}
                    {/* <TextField label="Project" variant="outlined" placeholder="Project"  {...register("project", { required: true })} margin="normal" /> */}
                    <Typography variant="subtitle1">Please select the project where the property is located. Note: projects are not officially in DLD (and MAPPI) are not accepted.</Typography>

                    <Grid2 container pb={0}>
                      <Grid2 container xs={12} md={12}>
                        <Grid2 item xs={3} md={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="type-select-label">Type</InputLabel>
                            <Select
                              defaultValue={"Apartment"}
                              labelId="type-label"
                              id="type"
                              label="Floor"
                              {...register("type", { required: { value: true, message: 'This field is required' } })}
                              error={Boolean(errors.type)} helperText={errors.type ? errors.type.message : ''}
                            >
                              <MenuItem value={"Apartment"}>Apartment/Flat</MenuItem>
                              <MenuItem value={"Villa"}>Villa</MenuItem>
                              <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid2>
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label="Size" variant="outlined" placeholder="Size" {...register("size", { required: { value: true, message: 'This field is required' } })}
                          error={Boolean(errors.size)} helperText={errors.size ? errors.size.message : ''} InputProps={{
                            endAdornment: <InputAdornment position="end">sqft</InputAdornment>,
                          }} margin="normal" fullWidth />
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label="Bedrooms" variant="outlined" placeholder="Bedrooms" {...register("bedrooms", { required: { value: true, message: 'This field is required' } })}
                          error={Boolean(errors.bedrooms)} helperText={errors.bedrooms ? errors.bedrooms.message : ''} margin="normal" fullWidth />
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label="Bathrooms" variant="outlined" placeholder="Bathrooms" {...register("bathrooms", { required: { value: true, message: 'This field is required' } })}
                          error={Boolean(errors.bathrooms)} helperText={errors.bathrooms ? errors.bathrooms.message : ''} margin="normal" fullWidth />
                      </Grid2>

                      <Grid2 item xs={3} md={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="demo-simple-select-label">Floor</InputLabel>
                          <Select
                            defaultValue={""}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Floor"
                            {...register("floor", { required: { value: true, message: 'This field is required' } })}
                            error={Boolean(errors.floor)}
                            inputProps={{
                              ...props,
                              helperText: errors.floor ? errors.floor.message : '',
                            }}
                          >
                            <MenuItem value={"High"}>High</MenuItem>
                            <MenuItem value={"Middle"}>Middle</MenuItem>
                            <MenuItem value={'Low'}>Low</MenuItem>
                          </Select>
                          <FormHelperText error>{errors.floor ? errors.floor.message : ''}</FormHelperText>
                        </FormControl>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        Price and commision
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container pb={0}>
                      <Grid2 container xs={12} md={12} p={0}>
                        <Grid2 xs={12} md={3} p={0}>
                        <NumericFormat
                          label="Price"
                          customInput={TextField}
                          thousandSeparator={true}
                          onValueChange={(v) => {
                            setValue('price',v.value)
                          }}
                          {...register("price", { required: { value: true, message: 'This field is required' }, min: { value: 80000, message: 'The price should be at least 80.000' } })}
                          variant="outlined" 
                          placeholder="Price" 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                          }}
                          error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''}
                          name="price"
                          margin="normal"
                          fullWidth
                        />
                          {/* <TextField label="Price" variant="outlined" placeholder="Price" InputProps={{
                            endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                          }} margin="normal" fullWidth {...register("price", { required: { value: true, message: 'This field is required' }, min: { value: 100000, message: 'The price should be at least 100.000' } })}
                            error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''} /> */}
                        </Grid2>
                        <Grid2 xs={12} md={9} p={2} alignContent={'center'} display={'flex'}>
                          <FormControlLabel control={<Checkbox color={'error'} margin="normal" {...register("priceBrokersOnly", {})} />} label="Show price only to Verified Agents" />
                        </Grid2>
                        <Grid2 xs={12} md={12} p={0}>
                          <Alert severity="info">The price should be the full price of the property incl. all the commisions.</Alert>
                        </Grid2>
                      </Grid2>

                      <Grid2 container xs={12} md={12} p={0} mb={-1}>
                        <Grid2 xs={3} md={3} p={0} pt={2}>
                          <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("shareCommision", {})} />} label="I am ready to share the commission" />
                        </Grid2>
                        <Typography variant="subtitle1">In case the offer will come from another Agent, will you be able to share the commssion? Remeber, MAPPi has many professionals registred in the system.</Typography>
                      </Grid2>

                      <Grid2 container xs={12} md={12} p={0}>
                        <Grid2 xs={3} md={3} p={0}>
                          <TextField label="Shared commision" variant="outlined" placeholder="Shared commision" {...register("commision", {})} InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }} margin="normal" fullWidth disabled={!watch('shareCommision')} />
                        </Grid2>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        Description and photos
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container xs={12} md={12} p={0}>
                      <Grid2 xs={6} md={6} p={0}>
                        <FormGroup>
                          <TextField label="Offer title" variant="outlined" placeholder="Title" {...register("title", { required: { value: true, message: 'This field is required' } })}
                            error={Boolean(errors.title)} helperText={errors.title ? errors.title.message : ''} margin="normal" />
                          <TextField label="Description" variant="outlined" {...register("description", { required: { value: true, message: 'This field is required' } })}
                            error={Boolean(errors.description)} helperText={errors.description ? errors.description.message : ''} margin="normal" multiline
                            rows={7} InputLabelProps={{ shrink: !!watch('description') }} />
                          <Box sx={{ float: 'left' }}>
                            <Button disabled={generateLoading} variant="outlined" color="primary" size="small" onClick={() => generateDesc()}>{generateLoading ? '...Wait a sec..' : 'Generate description'}</Button>
                            <FormHelperText error>{generateError ? 'Please enter all the datails of the property first' : ''}</FormHelperText>
                          </Box>
                        </FormGroup>
                      </Grid2>
                      <Grid2 xs={6} md={6} pb={0} p={0} pl={2} pt={2} >
                        <FilesDropzone
                          value={getValues().images}
                          {...register("images", { required: { value: true, message: 'At least one image is required' } })}
                          onChange={(value) => setValue('images', value)}
                          multiple
                        />
                        <FormHelperText error>{errors.images ? errors.images.message : ''}</FormHelperText>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        Contacts
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container xs={12} md={12} p={0}>
                      <Grid2 xs={6} md={6} p={0}>
                        <TextField InputLabelProps={{ shrink: !!watch('email') }} fullWidth error={Boolean(errors.email)} helperText={errors.email ? errors.email.message : ''} label="Email" variant="outlined" placeholder="Address" {...register("email", { required: { value: true, message: 'This field is required' } })} margin="normal" />
                        <Typography variant="subtitle1">
                          Please note, this email will not be shown to the users All the offers will be comming from <b>notifications@mappi.ae</b>. Please whitelist this email to avoid losing the opportunities.
                        </Typography>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        Exclusivity
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 xs={12} md={12} pb={3} pt={2}>
                      <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("alreadyListed", {})} />} label="This property is listed on other portals (PropertyFinder, etc)" />
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        You must know
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 xs={12} md={12} pb={5} pt={2}>
                    <Typography variant="body" pb={1}  component="div">
                        - On MAPPI, the seller is in the driver's seat. The buyer will not be able to see your contact details, but will be able to send you an offer via MAPPI with his contact details included. You will be responsible for getting in touch with him directly;
                      </Typography>
                      <Typography variant="body" pb={1}  component="div">
                        - You will not be able to edit the posting after it is published, with exception of the price and the commission. If you need to make any changes, you will need to unpublish the posting and create a new one;
                      </Typography>
                      <Typography variant="body" pb={3}  component="div">
                        - The posting will be valid for 30 days and removed after. You will be able to re-post it again after.
                      </Typography>
                      <Typography variant="subtitle1" pb={3} component="div">
                        By posting this special offer you accept that this posting can be moderated and validated by MAPPI.
                        You acknowledge that if any faulty information is provided, all your postings will be deleted and account will be blocked. You acknowledge that MAPPI has the right to delete any posting without any explanation.
                      </Typography>
                      <Button size="large" type="submit" variant="contained" color="primary" fullWidth pb={20}>Post the Special Offer</Button>
                    </Grid2>
                  </FormGroup>
                </Box>
              )}

            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default AddAd;