import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Bathroom, Bed, Frame } from 'iconoir-react';
import { trimStringLenght, formatDate, formatNumber, stripName } from '../../utils';


const FullItem = (props) => {
  const { item, navigate } = props;
  console.log('item', item)
  return (
    <Grid2 item xs={12} md={4} p={2}>
      <Card onClick={() => navigate(`/special/${item.id}`)}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image={item?.images[0]?.url || 'https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp'}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h1" component="div">
              {trimStringLenght(item?.project?.name, 30)}
            </Typography>
            <Typography gutterBottom variant="h3" component="div" pb={1} sx={{ fontWeight: 800 }}>
              {trimStringLenght(item?.title, 30)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ minHeight: '60px', maxHeight: '60px' }}>
              {trimStringLenght(item?.description, 140)}
            </Typography>
            <Divider sx={{ padding: 1 }} />
            <Grid2 container xs={12} md={12} pt={2} pb={2}>
              <Grid2 container xs={4} md={4} sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Chip label={item?.type} sx={{ fontWeight: 600, fontSize: '14px' }} />
              </Grid2>
              <Grid2 container xs={2.5} md={2.5} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Bed strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}>{formatNumber(item?.bedrooms)} Beds</Typography>
              </Grid2>
              <Grid2 container xs={2.5} md={2.5} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Bathroom strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}>{formatNumber(item?.bathrooms)} Baths</Typography>
              </Grid2>
              <Grid2 container xs={3} md={3} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Frame strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}>{formatNumber(item?.size)} Ft²</Typography>
              </Grid2>
            </Grid2>
            <Divider />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid2 container xs={12} md={12} pr={1} pl={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            {item?.price === 'hidden' ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h2" sx={{ marginTop: '3px', color: '#fc5c65', fontWeight: 800 }}>**** AED</Typography>
                <Typography variant="subtitle1" sx={{ marginTop: '3px' }}>Open for verified Agents</Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h2" sx={{ marginTop: '3px', color: '#fc5c65', fontWeight: 800 }}>{formatNumber(item?.price)} AED</Typography>
                <Typography variant="subtitle1" sx={{ marginTop: '3px' }}>{formatNumber(Math.floor(item?.price / item?.size))} AED/Ft²</Typography>
              </Box>
            )}
            <Button size="small" color="primary" variant='outlined' sx={{ padding: '2px 10px', fontSize: '14px' }}>
              Learn more
            </Button>
          </Grid2>
        </CardActions>
        <CardHeader
          avatar={
            <Avatar sx={{ width: 28, height: 28 }} aria-label="recipe" src={item?.user?.avatar}>
              M
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={stripName(item?.user?.name)}
          subheader={`Posted: ${formatDate(item?.createdAt)}`}
        />
      </Card>
    </Grid2>
  )
}

export default FullItem;