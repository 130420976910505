import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import { trackEvent } from '../components/common/plausible';
import API from '../controllers/secureApi.js';

import QuiteHere from './common/quiteHere'

import { format } from 'date-fns'

const Wallet = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);

  const cancelSubscription = async (subscriptionId) => {
    const { data } = await API.post(`/billing/cancel-subscription`, {
      subscriptionId
    })
    navigate('/wallet');
  }


  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (!dbUser) return;
      setLoading(true);
      if(dbUser.isStripeUser === false) {
        setLoading(false);
        return;
      }
      const { data } = await API.get(`/billing/subscriptions`)

      // filter out the subscriptions that are not active
      const active = data.data.filter(subscription => subscription.status === 'active');
      setSubscriptions(data.data);
      setActiveSubscriptions(active);
      console.log('subscriptions', data);
      console.log('Active', active);
      setLoading(false);
    }

    fetchSubscriptions();
  }, [dbUser]);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: {xs: 0, sm: '95px'}}}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {t('wallet.title')}
                </Typography>
                <Typography variant="subtitle1"> {t('wallet.subTitle')}</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            {loading ? (
              <>
                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={200} />
                </Grid2>
                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={200} />
                </Grid2>
                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={200} />
                </Grid2>
              </>
            ) : (

              <Grid2 container xs={12} md={12} justifyContent={'center'} p={0}>
                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Grid2 item xs={12} sm={12} container sx={{
                    border: '1px solid #0000001f',
                    borderRadius: '5px',
                    padding: 2,
                    backgroundColor: '#ffefba',
                    background: 'linear-gradient(to top, #ffefba, #ffffff)',
                    backgroundColor: '-webkit-linear-gradient(to top, #ffefba, #ffffff)'
                  }}>
                    <Grid2 item xs={8} sm={10}>
                      <Typography variant="h6"> {t('wallet.planTitle')}</Typography>
                      <Typography variant="h1">{activeSubscriptions.length ? 'PRO' : 'FREE'}</Typography>
                      <Typography variant="subtitle1">
                        Expires on {loading ? '' : activeSubscriptions.length ? format(new Date(activeSubscriptions[0].current_period_end * 1000), 'dd-MM-yyyy') : 'never'}
                      </Typography>
                      {activeSubscriptions.length ? (
                        <>
                          <Typography variant="h5" pt={4}>{t('wallet.notHappy')}</Typography>
                          <Button variant="contained" sx={{ mt: 2 }} onClick={() => cancelSubscription(activeSubscriptions[0].id)}>{t('wallet.cancel')}</Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="h5" pt={4}>{t('wallet.getStarted')}</Typography>
                          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/checkout')}>{t('wallet.upgrade')}</Button>
                        </>
                      )}
                    </Grid2>
                  </Grid2>
                </Grid2>

                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Grid2 item xs={12} sm={12} container sx={{ border: '1px solid #0000001f', borderRadius: '5px', padding: 2 }}>
                    <Grid2 item xs={8} sm={10}>
                      <Typography variant="h6">{t('wallet.paymentMethod')}</Typography>
                      <Typography variant="h1">
                        {activeSubscriptions[0]?.default_payment_method?.card?.last4 ?
                          `Card ***${activeSubscriptions[0]?.default_payment_method?.card?.last4}` : 'No payment method'}
                      </Typography>
                      <Typography variant="subtitle1">{activeSubscriptions[0]?.default_payment_method?.card?.brand.toUpperCase() || '-'}</Typography>
                      <Typography variant="h5" pt={4}>{t('wallet.wantToChange')}</Typography>
                      <Button variant="outlined" sx={{ mt: 2 }} disabled>{t('wallet.updatePM')}</Button>
                    </Grid2>
                  </Grid2>
                </Grid2>

                <Grid2 item xs={12} sm={4} sx={{ padding: 2 }}>
                  <Grid2 item xs={12} sm={12} container sx={{ border: '1px solid #0000001f', borderRadius: '5px', padding: 2 }}>
                    <Grid2 item xs={8} sm={10}>
                      <Typography variant="h6">{t('wallet.balance')}</Typography>
                      <Typography variant="h1">0 €</Typography>
                      <Typography variant="subtitle1">{t('wallet.lastTopUp')}</Typography>
                      <Typography variant="h5" pt={4}>{t('wallet.needMorePower')}</Typography>
                      <Button variant="contained" sx={{ mt: 2 }} disabled>{t('wallet.topUp')}</Button>
                    </Grid2>
                  </Grid2>
                </Grid2>

                <Grid2 item xs={12} sm={12} sx={{ padding: 2 }} mt={10}>
                  <QuiteHere message={t('wallet.transactionsSoon')} />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Wallet;