const en = {
  translation: {
    favourites: {
      title: 'My Favourites',
      subTitle: 'Keeping your portfolio in one place',
      noData: 'Looks like no any favorites for now. Start by adding some Projects or Districts from the map.',
    },
    checkout: {
      title: 'Checkout',
      subTitle: 'Choose your subscription plan',
      monthly: 'Monthly',
      yearly: 'Yearly',
      free: 'Free',
      freeCaps: 'FREE',
      freeDesc: 'Get access to basic data and statistics.',
      freeForever: 'Free forever',
      basicInfo: 'Basic information',
      freeCTA: 'Use free version',
      proCTA: 'Start 14 days trial',
      pro: 'Pro',
      proDesc: '14 days free trial',
    },
    payment: {
      title: 'Payment',
      subTitle: 'Choose your payment method',
      success: {
        title: 'Payment successful',
        subTitle: 'Your payment was processed successfully and your MAPPI Pro account is now active. We really hope to help you and your bussiness grow! You can cancel or change your subscription at any time in Your Wallet.',
        cta: 'Explore MAPPI',
      },
      error: {
        title: 'Payment error',
        subTitle: 'Something went wrong with your payment. Please try again later.',
        cta: 'Try again',
      },
    },
    paymentStatus: {
      title: 'You are awesome!',
      subTitle: 'We are processing your payment',
    },
    support: {
      title: 'About MAPPI',
      subTitle: 'MAPPI is the great tool for the real estate market',
      content: {
        h1: 'About MAPPI',
        c1: 'Welcome to MAPPI, your ultimate resource for navigating the dynamic landscape of Dubai\'s real estate market. We understand that making property decisions can be both exciting and challenging, which is why we\'ve crafted a platform that empowers you with accurate, transparent, and up-to-date insights.',
        c12: 'Whether you\'re a first-time homebuyer, a seasoned investor, or a real estate professional, MAPPI provides the tools you need to navigate the ever-evolving real estate terrain in Dubai.At MAPPI, we empower investors, homebuyers, and real estate professionals with data sourced from reliable authorities, including the Dubai Land Department. Dive into comprehensive district overviews, explore project insights, and track price trends with ease. Our platform is designed to be your buzz-free zone, ensuring you experience the real essence of UAE real estate.',
        h2: 'Walkthrough video',
        c2: 'Watch the video below to learn more about MAPPI and how to use it.',
        h3: 'FAQs',
        c3: 'Below you can find the answers to the most frequently asked questions. If you have any other questions, please contact us.',
        q1: 'What is MAPPI?',
        a1: 'MAPPI is a platform that provides accurate, transparent, and up-to-date insights about Dubai\'s real estate market. It is designed to be your buzz-free zone, ensuring you experience the real essence of UAE real estate.',
        q2: 'Where does MAPPI get the data from?',
        a2: 'We get the data from reliable authorities, including the Dubai Land Department.',
        q3: 'How often is the data updated?',
        a3: 'The data is updated daily.',
        q4: 'How can I use MAPPI?',
        a4: 'You can use MAPPI to explore the entire Dubai real estate landscape, including the districts and projects. You can also find the latest news and updates from the community, as well as the official documents of the projects.',
        q5: 'How can I find a specific project or district?',
        a5: 'You can use the search bar on the top of the page to find a specific project or district.',
        q6: 'How can I find the projects in a specific district?',
        a6: 'You can use the search bar on the top of the page to find a specific district. Once you are on the district page, you will find all the projects in that district.',
        q7: 'Can I integrate MAPPI with my CRM?',
        a7: 'Yes, you can. Please contact us for more information.',
        q8: 'Are the transactions and prices accurate?',
        a8: 'Yes, they are. We get the data from reliable authorities, including the Dubai Land Department. And we do not manipulate the data in any way, as we believe in transparency.',
      },
      contactUs: 'Contact us',
      contactDesc: 'Do you have any questions? Please check information provided in the tooltips for each section, review our FAQs section or contact us.',
    },
    wallet: {
      title: 'My wallet',
      subTitle: 'Manage your subscription and balances',
      planTitle: 'Current subscription plan',
      notHappy: 'You are not happy?',
      cancel: 'Cancel',
      upgrade: 'Upgrade (14 days trial)',
      getStarted: 'Get started with PRO',
      paymentMethod: 'Payment method',
      wantToChange: 'Want to change?',
      updatePM: 'Update payment method',
      balance: 'Current balance',
      lastTopUp: 'Last top-up: never',
      needMorePower: 'Need more power?',
      topUp: 'Top-up',
      transactionsSoon: 'History of the transactions is coming soon!',
    },
    dubai: {
      title: 'Dubai Market Overview',
      description: 'Navigate the Entirety of Dubai\'s Real Estate Landscape! Immerse yourself in the panoramic view of Dubai\'s real estate scene with our newest feature, the "Dubai Market Overview." This all-encompassing section provides an expansive understanding of Dubai, enabling you to explore and assess real estate trends, developments, and opportunities throughout the entire city. Whether you\'re a seasoned investor or a first-time buyer, the \"Dubai Market Overview\" is your compass for making well-informed decisions in the dynamic Dubai real estate market. Embark on your journey today and unlock a wealth of insights to navigate the diverse offerings of Dubai!',
      basicSection: {
        title: 'Statistics',
        subTitle: 'Transaction statistics for Dubai',
        tooltip: 'You can adjust the year to see the statistics for a specific year. No magic is happening here, we are just filtering the transactions for the selected year to provide you with the basic insights.',
        total: 'Total transactions',
        average: 'Avg. price',
        max: 'Max. price',
        apartTotal: 'Apartments transactions',
        apartAverage: 'Avg. price',
        apartMax: 'Max. price',
        villaTotal: 'Villas transactions',
        villaAverage: 'Avg. price',
        villaMax: 'Max. price',
      },
      sqmPriceSection: {
        title: 'Price trend',
        subTitle: 'The average price per square foot in Dubai',
        tooltip: 'Here you can see how the price per square foot has been evolving over time. The red line represents the average price per square foot for Dubai, while the gray line represents the moving average of the price per square foot. The moving average shows the overall trend and is calculated by taking the average of the price per square foot for the last 6 months. The graph also shows the transaction volume for the district that can be used to understand the market activity.',
        graph: {
          axe1: 'Avg price',
          axe2: 'Price moving avg',
          axe3: 'Transaction volume',
        },
        priceChange: {
          title: 'Price change',
          tooltip: 'Here you can see the price change for the selected period.',
        },
      },
      upcomingProjectsSection: {
        title: 'Upcoming projects',
        subTitle: 'Projects we will see launching the next months',
        tooltip: 'Here you can see the upcoming projects in Dubai. You will never see here any projects that are not regestered in the DLD, so you can be sure that the projects listed here are real. Please note that the expected completion date is an estimate and might change over time.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in Dubai. It seems nothing new is planned to be launched here???'
        },
      },
      delayedProjectsSection: {
        title: 'Delayed projects',
        subTitle: 'Projects at risk not been delivered',
        tooltip: 'Here you can see the delayed projects in Dubai. Please note that the expected completion date is an estimate and might change over time, however is the commitment f the developer in the DLD.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in Dubai. It seems nothing new is delayed here. Nice!'
        },
      },
      newProjectsSection: {
        title: 'New projects',
        subTitle: 'Projects that have been recently launched',
        tooltip: 'Here you can see the new projects in Dubai. This projects are on the initial stage, but are already registered in DLD. You will not find any unregistered projects here.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in Dubai. It seems nothing new is planned here???'
        },
      },
      analyticsSection: {
        resale: {
          title: 'Best resale',
          subTitle: 'Types of properties with the best resale rate',
          tooltip: 'Here you can see the types of properties with the best resale rate. This is a good indicator of the popularity of the type of property. For example, if you are looking to buy a property, you might want to consider the types of properties with the best resale rate as they are more likely to be sold in the future. The resale rate is calculated in percentage and is based on the amount of properties that have been re-sold. For example: the resale of 1 B/R apartment with rate of 100% means that each one bedroom apartment was resold at least once.',
        },
        distribution: {
          title: 'Price distribution',
          subTitle: 'Distribution of the transactions by the price range and volume',
          tooltip: 'Here you can see the distribution of the transactions by the price range and volume. This is a good indicator of the price range that is the most popular in Dubai. For example, if you are looking to buy a property, you might want to consider the price range that is the most popular as it is more likely to be sold in the future.',
          tableNoData: 'Select any price range on the graph above to see the Projects in that range.',
        },
      },
      topSellerSection: {
        title: 'Top sellers in',
        subTitle: 'Top 5 projects based volume of the transactions',
        tooltip: 'Here you can see the top 10 projects in Dubai based on the volume of transactions. This is a good indicator of the popularity of the project and the demand for the units in the project. However, be aware, that the volume of transactions is not the only indicator of the popularity of the project. For example, a project with a high volume of transactions but with a high number of units might not be as popular as a project with a lower volume of transactions but with a lower number of units.',
      },
      transactionCalendarSection: {
        title: 'Transaction calendar for',
        subTitle: 'Amount of transactions per day of the week',
        tooltip: 'Here you can see the amount of transactions per day of the week. This is a good indicator of the market activity. For example, if you are looking to buy a property, you might want to avoid the timeframes with the highest amount of transactions as the competition might be higher.',
      },
    },
    general: {
      expoloremap: 'Explore the map',
      login: 'Login',
      register: 'Register',
      search: 'Search for projects or districts',
      cta: {
        register: {
          title: 'Register',
          subTitle: 'This functionality is available for registered users only. Registration is free and takes less than a minute.',
        },
        registerPro: {
          title: 'Register',
          subTitle: 'This functionality is available for registered users with PRO subscription only. Free trial is avaliable for 14 days. No credit card required.',
        },
        login: {
          title: 'Login',
          subTitle: 'Login to get access to all features',
        },
      },
      goPro: {
        title: 'Go Pro',
        subTitle: 'This functionality is available for registered users with PRO subscription only. Free trial is avaliable for 14 days. No credit card required.',
        button: 'Upgrade to Pro',
      },
      topNav: {
        contactUs: 'Contact us',
        terms: 'Terms & Conditions',
        privacy: 'Privacy Policy',
        subscription: 'Manage subscription',
        logout: 'Logout',
        biDashboard: 'BI Dashboard (enterprise)',
        faq: "FAQs",
        home: "Home",
        about: "About",
        singIn: 'Sign in',
        upgrade: 'Upgrade to Pro',
        support: {
          title: 'Support',
          subTitle: 'Do you have any questions? Please check information provided in the tooltips for each section, review our FAQs section or contact us.',
          name: 'Your name',
          email: 'Email',
          message: 'What do you want to know about?',
          button: 'Send',
          success: 'Your message has been sent successfully. We will get back to you as soon as possible.',
          fail: 'Something went wrong. Please try again later.',
        },
      }
    },
    district: {
      h1: 'District:',
      filters: {
        year: 'Year',
        propTypes: 'Property types',
        regTypes: 'Registration types',
        unitTypes: 'Unit types',
      },
      tabs: {
        titleOne: 'Basic statistics',
        subTitleOne: 'Statistics for the district',
        titleTwo: 'Analytics',
        subTitleTwo: 'Useful insights',
        titleThree: 'Projects',
        subTitleThree: 'All projects in one place',
        titleFour: 'Developers',
        subTitleFour: 'Who is who on the market',
      },
      basicSection: {
        title: 'Statistics',
        subTitle: 'Transaction statistics for the district',
        tooltip: 'You can adjust the year to see the statistics for a specific year. No magic is happening here, we are just filtering the transactions for the selected year to provide you with the basic insights.',
        total: 'Total transactions',
        average: 'Avg. price',
        max: 'Max. price',
        apartTotal: 'Apartments transactions',
        apartAverage: 'Avg. price',
        apartMax: 'Max. price',
        villaTotal: 'Villas transactions',
        villaAverage: 'Avg. price',
        villaMax: 'Max. price',
      },
      developerDistribution: {
        title: 'Developer distribution',
        subTitle: 'Distribution Projects by Developers',
        tooltip: 'Here you can see the distribution of the Projects by Developers. This is a good indicator of the popularity of the Developer. For example, if you are looking to buy a property, you might want to consider the Developers with the best distribution as they are more likely to be sold in the future.',
      },
      developersRatingsSection: {
        title: 'Developers rating',
        subTitle: 'Developers rating',
        tooltip: 'Here you can see the Developers rating. This is a good indicator of the popularity of the Developer. For example, if you are looking to buy a property, you might want to consider the Developers with the best rating as they are more likely to be sold in the future.',
        table: {
          name: 'Developer',
          rating: 'Mappi Rating',
          cancelled: 'Cancelled',
          active: 'Active',
          finished: 'Finished',
          nodata: 'No data found. \n Can be the case if the DLD has no Developers information for the Projects in the District.',
        },
      },
      analyticsSection: {
        resale: {
          title: 'Best resale',
          subTitle: 'Types of properties with the best resale rate',
          tooltip: 'Here you can see the types of properties with the best resale rate. This is a good indicator of the popularity of the type of property. For example, if you are looking to buy a property, you might want to consider the types of properties with the best resale rate as they are more likely to be sold in the future. The resale rate is calculated in percentage and is based on the amount of properties that have been re-sold. For example: the resale of 1 B/R apartment with rate of 100% means that each one bedroom apartment was resold at least once.',
        },
        distribution: {
          title: 'Price distribution',
          subTitle: 'Distribution of the transactions by the price range and volume',
          tooltip: 'Here you can see the distribution of the transactions by the price range and volume. This is a good indicator of the price range that is the most popular in the district. For example, if you are looking to buy a property, you might want to consider the price range that is the most popular as it is more likely to be sold in the future.',
          tableNoData: 'Select any price range on the graph above to see the Projects in that range.',
        },
      },
      sqmPriceSection: {
        title: 'Price trend',
        subTitle: 'The average price per square foot in the district',
        tooltip: 'Here you can see how the price per square foot has been evolving over time. The red line represents the average price per square foot for the district, while the gray line represents the moving average of the price per square foot. The moving average shows the overall trend and is calculated by taking the average of the price per square foot for the last 6 months. The graph also shows the transaction volume for the district that can be used to understand the market activity.',
        graph: {
          axe1: 'Avg price',
          axe2: 'Price moving avg',
          axe3: 'Transaction volume',
        },
        priceChange: {
          title: 'Price change',
          tooltip: 'Here you can see the price change for the selected period.',
        },
      },
      transactionCalendarSection: {
        title: 'Transaction calendar for',
        subTitle: 'Amount of transactions per day of the week',
        tooltip: 'Here you can see the amount of transactions per day of the week. This is a good indicator of the market activity. For example, if you are looking to buy a property, you might want to avoid the timeframes with the highest amount of transactions as the competition might be higher.',
      },
      topSellerSection: {
        title: 'Top sellers in',
        subTitle: 'Top 5 projects based volume of the transactions',
        tooltip: 'Here you can see the top 5 projects in the district based on the volume of transactions. This is a good indicator of the popularity of the project and the demand for the units in the project. However, be aware, that the volume of transactions is not the only indicator of the popularity of the project. For example, a project with a high volume of transactions but with a high number of units might not be as popular as a project with a lower volume of transactions but with a lower number of units.',
      },
      upcomingProjectsSection: {
        title: 'Upcoming projects',
        subTitle: 'Projects we will see launching the next months',
        tooltip: 'Here you can see the upcoming projects in the district. You will never see here any projects that are not regestered in the DLD, so you can be sure that the projects listed here are real. Please note that the expected completion date is an estimate and might change over time.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in this District. It seems nothing new is planned to be launched here.'
        },
      },
      delayedProjectsSection: {
        title: 'Delayed projects',
        subTitle: 'Projects at risk not been delivered',
        tooltip: 'Here you can see the delayed projects in the district. Please note that the expected completion date is an estimate and might change over time, however is the commitment f the developer in the DLD.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in this District. It seems nothing new is delayed here. Nice!'
        },
      },
      newProjectsSection: {
        title: 'New projects',
        subTitle: 'Projects that have been recently launched',
        tooltip: 'Here you can see the new projects in the district. This projects are on the initial stage, but are already registered in DLD. You will not find any unregistered projects here.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          expectedAt: 'Expected date',
          developer: 'Developer',
          nodata: 'No data is avaliable in this District. It seems nothing new is planned here.'
        },
      },
    },
    project: {
      h1: 'Project:',
      tabs: {
        titleOne: 'Basic information',
        subTitleOne: 'Let\'s start with the basics',
        titleTwo: 'Avalability',
        subTitleTwo: 'List of units available',
        titleThree: 'Documents',
        subTitleThree: 'Document cloud',
        titleFour: 'Transactions',
        subTitleFour: 'All transactions',
        titleFive: 'News and updates',
        subTitleFive: 'Posted by community',
        titleSix: 'Market Data',
        subTitleSix: 'What is on the market',
      },
      news: {
        title: 'Post your news',
        subTitle: 'Share your news and updates with the community',
        button: 'Post a message',
        placeholder: 'Type your message here...',
        cta: "Post your news",
        note: "Please take note that every submission is subject to a moderation procedure. Typically, advertisements are acceptable, but any inappropriate content will lead to the deactivation of your account. For users with a Pro account, their postings are displayed online before undergoing moderation, while those under the free tier have their postings published after moderation. The moderation process usually takes about one hour but may extend to up to 24 hours."
      },
      basicSection: {
        status: 'Status',
        completion: 'Completion',
        number: 'Project #',
        startDate: 'Start date',
        completionDate: 'Completion date',
        registrationDate: 'Registration date',
        totalUnits: 'Total units',
        totalArea: 'Total area',
      },
      marketData: {
        title: 'For sale',
        subTitle: 'Units for sale on UAE’s real estate portals',
        tooltip: 'Here you can see the units for sale on UAE’s real estate portals. The data is extracted from the UAE’s real estate portals in real time and is not stored on MAPPI.',
        infoBox: {
          title: 'We can fetch the data for you!',
          context: 'However, be aware that the data is extracted from the UAE’s real estate portals in real time and is not stored on MAPPI. Therefore it can take some time to fetch the data, so please be patient.',
          cta: 'Fetch the data for me!',
        },
        fetching: 'Fetching the data, please wait. It should take less than 10 seconds.',
        sorry: 'Sorry, we cannot fetch the data for you.',
        cannotfetch: 'At this moment we can not fetch the data for this project. We will try to make the data available as soon as possible.',
        summary: {
          total: 'Total ads',
          min: 'Min. price',
          max: 'Max. price',
        },
      },
      typesSection: {
        title: 'Type of units',
        subTitle: 'Type of units in the project',
        tooltip: 'Here you can see the types of units in the project. This is just a high level overview of the types of units in the project. For more detailed information, please check Resale rate in the Transactions section.',
      },
      sqmTrendSection: {
        title: 'Ft² price trend',
        subTitle: 'The average price per square foot in the project',
        tooltip: 'Here you can see how the price per square foot has been evolving over time. The red line represents the average price per square foot for the project, while the gray line represents the moving average of the price per square foot. The moving average shows the overall trend and is calculated by taking the average of the price per square foot for the last 6 months. The graph also shows the transaction volume for the project that can be used to understand the market activity. Read about Income/Loss parameter below.',
        graph: {
          axe1: 'Avg price (Off-plan)',
          axe2: 'Price moving avg',
          axe3: 'Avg price (Ready)',
          volume: 'Transaction volume',
        },
      },
      incomeLossSection: {
        title: 'Income/Loss',
        tooltip: 'Here you can see the average income/loss for the project. The income/loss is calculated based on the average price per square foot for the project on the start of sale and the average price per square foot at the latest day of the year. For example, if the average price per square foot for the project is currently 1,000 AED and the average price per square foot on the start of the sale was 1,200 AED, the income/loss is -20%. This means that the current average price per square foot for the project is 200 AED lower than the average price per square foot on the sale start.',
        from: 'From',
        mid: 'AED/Ft² on sales start at',
      },
      resaleSection: {
        title: 'Resale rate',
        subTitle: 'Amount of properties re-sold in the project',
        tooltip: 'Here you can see the amount of properties re-sold in the project and (if any) the leftovers. This is a good indicator of the popularity of the project and the demand for the units in the project. For example, if you are looking to buy a property, you might want to consider the projects with the best resale rate as they are more likely to be sold in the future. The resale rate is calculated in percentage and is based on the amount of properties that have been re-sold minus 100%. For example: the resale of 1 B/R apartment with rate of 30% means that 30% one bedroom apartment was resold.',
        leftovers: {
          title: 'Leftovers',
          subTitle: 'Amount of units left for sale',
        },
      },
      documentCloud: {
        title: 'Document cloud',
        subTitle: 'All the official documents of the project',
        tooltip: 'Here you can find all the official documents of the project. You can download them by clicking on the download icon. Please note that the documents are not provided by the DLD and we are not responsible for the content of the documents.',
      },
      developerSection: {
        title: 'Developer',
        subTitle: 'The developer of the project',
        tooltip: 'This section contains Developer information, including contact details. The information is provided AS IS from the avaliable public sources, including rating. MAPPI however has internal rating of the Developer avaliable below. This raiting is based on many parameters and tryes to reflect the Developer\'s real performance on the market.',
        id: 'Developer ID',
        website: 'Website',
        email: 'Email',
        phone: 'Phone',
        mobile: 'Mobile',
      },
      developerProjectsSection: {
        title: 'Developer\'s rating and projects',
        subTitle: 'Find other developers\' Projects and Mappi rating (beta)',
        tooltip: 'This section contains Developer\'s Projects and Mappi rating. The rating is based on many parameters and tryes to reflect the Developer\'s real performance on the market. For example, Developer can have many Legal entities used on the market, all of those are aggregated into one rating. The rating is calculated based on the Developer\'s Projects performance, including the number of transactions, the number of units, the number of units left for sale, the number of units resold, etc.',
        table: {
          name: 'Project',
          completion: 'Completion (%)',
          status: 'Current status'
        },
      },  
      managmentCompanySection: {
        title: 'Managment company',
        subTitle: 'The managment company of the project',
        email: 'Email',
        phone: 'Phone',
        mobile: 'Mobile',
        licenseNumber: 'License number',
        companyId: 'Company ID',
      },
      escrowAccountSection: {
        title: 'Escrow account',
        subTitle: 'The escrow account of the project',
        bank: 'Bank',
        accountNumber: 'Account number',
      },
      transactions: {
        title: 'All the transactions',
        subTitle: 'Slice and dice the transactions',
      },
    },
  }
}

export default en
